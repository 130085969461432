export const DataSocial = [
  {
    'id': 1,
    'name': 'whatsApp',
    'url': 'https://api.whatsapp.com/send?phone=573124563067'
  },
  {
    'id': 2,
    'name': 'youtube',
    'url': 'https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g'
  },
  {
    'id': 3,
    'name': 'facebook',
    'url': 'https://www.facebook.com/papeleria.golden/'
  },
  {
    'id': 4,
    'name': 'instagram',
    'url': 'https://www.instagram.com/papeleriagolden/'
  },
  {
    'id': 5,
    'name': 'linkedIn',
    'url': 'https://www.linkedin.com/in/papeleria-golden-33b810100/'
  },
  {
    'id': 6,
    'name': 'twitter',
    'url': 'https://twitter.com/papeleriagolden'
  },
  {
    'id': 7,
    'name': 'pinterest',
    'url': 'https://co.pinterest.com/papeleriagolden/_saved/'
  },
]