export const police = [
  {
    'id': 1,
    'name': 'REPORTAR PÉRDIDA DE DOCUMENTOS',
    'video': 'Video',
    'linkVideo': 'https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g',
    'linkDocument': 'https://webrp.policia.gov.co:444/publico/Constancia.aspx',
    'value': '2.000',
    'register': 'No. de identificación'
  },
  {
    'id': 2,
    'name': 'CONSTANCIA PÉRDIDA DE DOCUMENTOS',
    'video': 'Video',
    'linkVideo': 'https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g',
    'linkDocument': 'https://webrp.policia.gov.co:444/publico/buscador_constancia.aspx',
    'value': '1.000',
    'register': 'No. de identificación'
  }
]