export const eps = [
  {
    id: 1,
    name: "ADRES FOSYGA",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://aplicaciones.adres.gov.co/bdua_internet/Pages/ConsultarAfiliadoWeb.aspx",
    value: "1.000",
    register: "No. de identificación",
  },
  {
    id: 2,
    name: "COMPROBADOR DE DERECHOS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://appb.saludcapital.gov.co/Comprobadordederechos/Consulta.aspx",
    value: "1.000",
    register: "No. de identificación",
  },
  {
    id: 3,
    name: "ALIANSALUD EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://www.aliansalud.com.co/oficinavirtual/Paginas/Login.aspx",
    value: "2.000",
    register: "Usuario y Clave",
  },
  {
    id: 4,
    name: "COOMEVA EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "http://cooeps.coomeva.com.co/prod/afiliacion/php/ajax/afiliado/indexCertificadosAfiliado.php",
    value: "1.000",
    register: "No. de identificación",
  },
  {
    id: 5,
    name: "COMPENSAR",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://seguridad2.compensar.com/sso/login/36656901cdc57c7f10b7467c55d315dd",
    value: "2.000",
    register: "Usuario y clave",
  },
  {
    id: 6,
    name: "CRUZ BLANCA EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument: "http://www.cruzblanca.com.co/",
    value: "2.000",
    register: "Usuario y clave",
  },
  {
    id: 7,
    name: "FAMISANAR EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument: "https://enlinea.famisanar.com.co/Portal/home.jspx",
    value: "2.000",
    register: "Usuario y clave",
  },
  {
    id: 8,
    name: "MEDIMÁS EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://heonmedimas.heon.com.co/medimas/Epsenlinea/EpsEnLineaAng/#/sesion",
    value: "2.000",
    register: "Usuario y clave",
  },
  {
    id: 9,
    name: "NUEVA EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument: "https://aplicaciones.nuevaeps.com.co/Portal/home.jspx",
    value: "2.000",
    register: "Usuario y clave",
  },
  {
    id: 10,
    name: "SANITAS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://www.epssanitas.com/usuarios/web/nuevo-portal-eps/inicio?p_p_id=58&p_p_lifecycle=0&p_p_state=maximized&p_p_mode=view&saveLastPath=false&_58_struts_action=%2Flogin%2Flogin#gsc.tab=0",
    value: "2.000",
    register: "Usuario y clave",
  },
  {
    id: 11,
    name: "SALUD TOTAL EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://transaccional.saludtotal.com.co/Transaccional/plataforma/ingresar.aspx?q=D4O1+zam3H0+eV6n57nEogCT0XrxOguzMGbpn45Tikq4KHHVCR3mIKneP3bObG5NkY2S8TQ4K+ZeLCFI3AV4NdvvrJHvJDTwKRAqgP0m69nzAb4vMi194FJ7CfTXPpiE/vhjPAXbxHYuHkkCv3jcxMAzjZj2oMFkbP3lrmALnCk=",
    value: "2.000",
    register: "Usuario y clave",
  },
  {
    id: 12,
    name: "SURA EPS",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument:
      "https://login.sura.com/sso/servicelogin.aspx?continueTo=https%3A%2F%2Fportaleps.epssura.com%2FServiciosUnClick%2F&service=epssura",
    value: "1.000",
    register: "Usuario y clave",
  },
  {
    id: 13,
    name: "MI VACUNA",
    video: "Video",
    linkVideo: "https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g",
    linkDocument: "https://mivacuna.sispro.gov.co/MiVacuna/Account/Login",
    value: "2.500",
    register: "No de identificación y fecha expedición",
  },
];
