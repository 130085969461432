export const sisben = [
  {
    'id': 1,
    'name': 'PUNTAJE DEL SISBEN',
    'video': 'Video',
    'linkVideo': 'https://www.youtube.com/channel/UCvi2KWTO8kKJZUO8cdJ2b6g',
    'linkDocument': 'https://reportes.sisben.gov.co/dnp_sisbenconsulta/dnp_sisben_consulta.aspx',
    'value': '1.000',
    'register': 'No. de identificación'
  }
]