import React from 'react';

const PageNotFound = () => {
  return (
    <>
      Pagina no Encontrada
    </>
  )
};

export default PageNotFound;