export const DataFireplace = [
  {
    'id': 1,
    'name': 'fireplace1'
  },
  {
    'id': 2,
    'name': 'fireplace2'
  },
  {
    'id': 3,
    'name': 'fireplace3'
  },
  {
    'id': 4,
    'name': 'fireplace4'
  },
  {
    'id': 5,
    'name': 'fireplace5'
  },
  {
    'id': 6,
    'name': 'fireplace6'
  },
  {
    'id': 7,
    'name': 'fireplace7'
  },
  {
    'id': 8,
    'name': 'fireplace8'
  },
  {
    'id': 9,
    'name': 'sheep'
  }
]